<template>
  <v-container v-if="readData">
    <page-title title="退貨申請" />

    <form-info :value="orderInfo" class="mb-5" />

    <bar class="mb-5" type="primary">
      <template v-slot:label> 訂單編號 </template>
      <template v-slot:value>{{ readData && readData.order_no }}</template>
    </bar>

    <customForm
      :getFormKey.sync="formKey"
      :slotComponents="slotComponents"
      :value="readData"
      :commonProps="{backOrderStatusText}"
    ></customForm>

    <v-btn depressed color="primary" @click="goBack" width="100%">返回</v-btn>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import customForm from "@/components/form/custom/customForm.vue";
export default {
  components: {
    customForm,
    formInfo: () => import('@/components/form/custom/formInfo.vue'),
  },
  data: () => ({
    readData : null,
    formKey: null,
  }),
  async created () {
    await this.beforeFormInit();
  },
  computed: {
    backOrder() {
      return this.readData.backOrder
    },
    status() {
      return this.readData.status
    },
    backOrderStatus() {
      return this.backOrder.backOrder_status
    },
    backOrderStatusText() {
      if(this.status == 'finished' && this.backOrderStatus == 'SUCCESS') return '部分退貨完成'
      if(this.status == 'canceled' && this.backOrderStatus == 'SUCCESS') return '全部退貨完成'
      return this.$t(`back-order.status.${this.backOrderStatus}`)
    },
    orderInfo() {
      return [
          {text: '退貨狀態', value: this.backOrderStatusText },
          {text: '申請時間', value: this.backOrder.created_at},
      ]
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerId() {
      return this.$route.params.providerId
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },

    slotComponents() {
      return [
        {
          values : ['*'],
          component : () => import('./backOrderItems.vue')
        },

      ];
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async beforeFormInit() {
      this.$store.dispatch('loading/active')
      try {
        await Promise.all([
          this.readApi()
        ])
      } catch(err) {
        
      } finally {
        this.$store.dispatch('loading/close')
      }

    },
    async readApi() {
      const res =  await this.$api.collection.orderApi.read(this.storeId,this.providerId,this.$route.params.orderId)
     
      if(!res) {
        this.$snotify.error('', '')
      } else {
        this.readData = res 
      }
      
    },

  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
